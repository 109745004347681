import { FluentTriangleDown12Filled } from '@/assets/icons/comm/FluentTriangleDown12Filled';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInput from '@/components/base/baseInput';
import BaseTabs from '@/components/base/baseTabs';
import PageEnum from '@/enums/pageEnum';
import RegistrationPrivacyAgreementDrawer from '@/pages/public/register/components/RegistrationPrivacyAgreementDrawer';
import {
  RegisterType,
  WAY_TO_REGISTER,
} from '@/pages/public/register/useStateHooks';
import useUrlState from '@ahooksjs/use-url-state';
import { Button, Checkbox, Image, cn } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import { VerifiedCodeEnum, VerifiedCodeTypeEnum } from '@/enums/businessEnum';

/**
 * 注册表单组件
 * @param {IProps} props - 组件属性
 * @returns {JSX.Element} - 注册表单的 JSX 元素
 */
export default (props: RegisterType) => {
  const { t } = useTranslation();
  const {
    sendMailApiRequest, // 发送验证码请求
    countdown,
    privacyAgreement,
    submitLoading,
    userAgreement,
    registerForm,
    setRegisterForm,
    setTargetDate,
    currentRegister,
    registerOption,
    setCurrentRegister,
    setTargetDateEmail,
    countdownEmail,
    sendMailLoading,
  } = props;
  //当前区号
  const { currentAreaCode } = useModel('areaCode');
  const [urlState] = useUrlState<any>({});
  const { invite: _invite, inviteCode } = urlState || {};
  const { isMobile } = useModel('system');
  const { appInfo } = useModel('appInfo');
  const invite = _invite || inviteCode;
  return (
    <div className="flex flex-col gap-4">
      {/*注册方式区分*/}
      <BaseTabs
        tabsPropsProps={{
          className: 'w-full ',
          classNames: {
            base: isMobile
              ? 'bg-backgroundAuxiliaryColor'
              : '!bg-[transparent]',
            tabList: 'w-full border-[1px] bg-backgroundColor',
          },
        }}
        classNames={{
          active: isMobile ? '' : '!bg-backgroundAuxiliaryColor/10',
        }}
        value={currentRegister}
        variant="bordered"
        onChange={setCurrentRegister}
        options={registerOption}
      />

      {/* 手机号输入框 */}
      {currentRegister === WAY_TO_REGISTER.PHONE && (
        <div className="flex flex-col gap-2 sm:text-[#0E0E0E]">
          <div
            className={cn(
              `flex items-center border-1 shadow-sm  border-[#efefef] rounded-md !h-[56px]`,
              {
                'border-[#efefef]': !isMobile,
                'border-borderColor': isMobile,
              },
            )}
          >
            <div
              className="flex items-center  h-full sm:cursor-pointer"
              onClick={() => {
                history.push(
                  `${PageEnum.CHOOSE_AREA}?targetUrl=${PageEnum.REGISTERED}&currentRegister=${currentRegister}&isKeepAlive=${1}`,
                );
              }}
            >
              <Image
                classNames={{
                  wrapper:
                    'w-[20px] ml-2  h-[20px] flex-shrink-0 rounded-full object-cover',
                }}
                className="w-[20px] h-[20px] flex-shrink-0 rounded-full  object-cover"
                src={(currentAreaCode as any)?.iconUrl}
              />
              <span>+{(currentAreaCode as any)?.areaCode}</span>
              <FluentTriangleDown12Filled
                className="text-backContrastColor ml-2"
                width="8px"
                height="8px"
              />
            </div>
            <BaseInput
              classNames={{
                input: 'sm:text-[#0E0E0E] !border-none',
                label: 'sm:!text-[#0E0E0E]',
                inputWrapper: 'sm:text-[#0E0E0E] !border-none shadow-none',
              }}
              value={registerForm?.phone}
              onChange={(e) => {
                setRegisterForm((prevState: any) => {
                  return {
                    ...prevState,
                    phone: e.target.value,
                  };
                });
              }}
              label={t('手机号')}
              isRequired
            />
          </div>
        </div>
      )}

      {/* 邮箱输入框 */}
      {currentRegister === WAY_TO_REGISTER.EMAIL && (
        <div className="flex flex-col gap-2">
          <BaseInput
            classNames={{
              input: 'sm:text-[#0E0E0E]',
              label: 'sm:!text-[#0E0E0E]',
              inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
            }}
            value={registerForm?.email}
            onChange={(e) => {
              setRegisterForm((prevState: any) => {
                return {
                  ...prevState,
                  email: e.target.value,
                };
              });
            }}
            label={t('邮箱')}
            isRequired
          />
        </div>
      )}

      {/* 验证码输入框 */}
      {
        <div className="flex flex-col gap-2">
          <div className="relative">
            <BaseInput
              value={registerForm?.captcha}
              onChange={(e) => {
                setRegisterForm((prevState: any) => {
                  return {
                    ...prevState,
                    captcha: e.target.value?.trim(),
                  };
                });
              }}
              label={t('验证码')}
              isRequired
              type="text"
              isShowClear={false}
              classNames={{
                input: 'sm:text-[#0E0E0E]',
                label: 'sm:!text-[#0E0E0E]',
                inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
              }}
            />
            <Button
              spinner={<LoadingSvg />}
              isLoading={sendMailLoading}
              onClick={() => {
                const emailRegex = /^[a-zA-Z0-9_.!#$%*-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,9}$/;
                if (currentRegister === WAY_TO_REGISTER.EMAIL && !emailRegex.test(registerForm?.email)) {
                  return toast.error(t('请输入正确的邮箱'));
                }

                if (currentRegister === WAY_TO_REGISTER.PHONE && countdown !== 0) {
                  return toast.error(t('验证码已发送，请您耐心等待{{times}}秒后再试。感谢您的理解与配合！', {
                    times: 30,
                  }));
                }
                if (currentRegister === WAY_TO_REGISTER.EMAIL && countdownEmail !== 0) {
                  return toast.error(t('验证码已发送，请您耐心等待{{times}}秒后再试。感谢您的理解与配合！', {
                    times: 30,
                  }));
                }
                if (
                  currentRegister === WAY_TO_REGISTER.PHONE &&
                  !registerForm?.phone.trim()
                ) {
                  return toast.error(t('请输入手机号'));
                }
                if (
                  currentRegister === WAY_TO_REGISTER.EMAIL &&
                  !registerForm?.email.trim()
                ) {
                  return toast.error(t('请输入邮箱'));
                }
                if (currentRegister === WAY_TO_REGISTER.PHONE) {
                  sendMailApiRequest({
                    to: registerForm?.phone.trim(),
                    type: VerifiedCodeEnum.REGISTER,
                    sendType: VerifiedCodeTypeEnum.PHONE,
                    areaCode: (currentAreaCode as any)?.areaCode,
                  });
                } else {
                  sendMailApiRequest({
                    to: registerForm?.email.trim(),
                    sendType: VerifiedCodeTypeEnum.EMAIL,
                    type: VerifiedCodeEnum.REGISTER,
                  });
                }

              }}
              className="absolute right-1 top-0 bottom-0 my-2 flex items-center justify-center  px-3 focus:outline-none !bg-[transparent] text-primary text-base cursor-pointer "
              type="button"
            >
              {
                currentRegister === WAY_TO_REGISTER.PHONE && <>
                  {countdown !== 0 ? (
                    <span className="text-foreground pr-3">
                                         {Math.round(countdown / 1000)}
                                       </span>
                  ) : (
                    t('获取验证码')
                  )}
                </>
              }
              {
                currentRegister === WAY_TO_REGISTER.EMAIL && <>
                  {countdownEmail !== 0 ? (
                    <span className="text-foreground pr-3">
                                         {Math.round(countdownEmail / 1000)}
                                       </span>
                  ) : (
                    t('获取验证码')
                  )}
                </>
              }
            </Button>
          </div>
        </div>
      }

      {/* 密码输入框 */}
      <div className="flex flex-col gap-2">
        <BaseInput
          value={registerForm?.password}
          onChange={(e) => {
            setRegisterForm((prevState: any) => {
              return {
                ...prevState,
                password: e.target.value,
              };
            });
          }}
          isRequired
          classNames={{
            input: 'sm:text-[#0E0E0E]',
            label: 'sm:!text-[#0E0E0E]',
            inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
          }}
          label={t('密码')}
          type="password"
        />
      </div>

      {/* 确认密码输入框 */}
      <div className="flex flex-col gap-2">
        <BaseInput
          value={registerForm?.repeatPassword}
          classNames={{
            input: 'sm:text-[#0E0E0E]',
            label: 'sm:!text-[#0E0E0E]',
            inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
          }}
          onChange={(e) => {
            setRegisterForm((prevState: any) => {
              return {
                ...prevState,
                repeatPassword: e.target.value,
              };
            });
          }}
          isRequired
          label={t('确认密码')}
          type="password"
        />
      </div>

      {/* 邀请码 */}
      {appInfo?.MEMBER_CODE_REQUIRED && <div className="flex flex-col gap-2">
        <BaseInput
          value={registerForm?.invitationCode}
          isRequired
          disabled={!!invite}
          isShowClear={!!!invite}
          classNames={{
            input: 'sm:text-[#0E0E0E]',
            label: 'sm:!text-[#0E0E0E]',
            inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
          }}
          onChange={(e) => {
            setRegisterForm((prevState: any) => {
              return {
                ...prevState,
                invitationCode: e.target.value,
              };
            });
          }}
          label={t('邀请码')}
        />
      </div>
      }
      <Button
        spinner={<LoadingSvg />}
        isLoading={submitLoading}
        onClick={props.submitRegister}
        className="mt-4 mainColorButton"
      >
        {t('点击注册')}
      </Button>
      <Button
        onClick={props.goLogin}
        className="w-full mx-auto rounded-md  bg-backgroundAuxiliaryColor  sm:!bg-[#f4f4f5] sm:border-none sm:text-[#0E0E0E] h-[45px]"
      >
        {t('登录')}
      </Button>
      <div className="mt-5">
        <div className="flex items-center">
          <Checkbox
            size="sm"
            defaultSelected
            color="default"
            isSelected={registerForm?.checked}
            classNames={{
              wrapper: cn(``, {
                'after:bg-[#f4f4f5] hover:!bg-[transparent] text-[#000] before:border-[#f4f4f5]':
                  !isMobile,
              }),
              base: 'hover:!bg-[transparent]',
            }}
            onValueChange={(value) => {
              setRegisterForm((prevState: any) => {
                return {
                  ...prevState,
                  checked: value,
                };
              });
            }}
          >
            <span
              className={cn(`mr-0.5 text-[10px]`, {
                '!text-auxiliaryTextColor': !isMobile,
              })}
            >
              {t('我已详细阅读并同意')}
            </span>
          </Checkbox>
          <RegistrationPrivacyAgreementDrawer
            title={t('用户协议')}
            privacyAgreement={userAgreement}
          >
            <span className="inline-block text-darkBrandColor text-[10px] cursor-pointer">
              {`《${t('用户协议')}》`}
            </span>
          </RegistrationPrivacyAgreementDrawer>
          <RegistrationPrivacyAgreementDrawer
            title={t('隐私协议')}
            privacyAgreement={privacyAgreement}
          >
            <span className="inline-block text-darkBrandColor text-[10px] cursor-pointer">
              {`《${t('隐私协议')}》`}
            </span>
          </RegistrationPrivacyAgreementDrawer>
        </div>
      </div>
    </div>
  );
};
