import { SvgSpinnersBarsScale } from '@/assets/icons/layout/SvgSpinnersBarsScale';
import BaseModal from '@/components/base/baseModal';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { ScrollShadow, useDisclosure } from '@nextui-org/react';
import { ReactNode } from 'react';

export default ({
  children,
  privacyAgreement,
  title,
  onClick,
  loading,
}: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <div
        className="flex justify-start items-center"
        onClick={() => {
          if (onClick) onClick();
          onOpen();
        }}
      >
        {children}
      </div>
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        classNames={{ mask: '!z-[9999999]' }}
      >
        <div className="mt-4 mb-10 px-4 ">
          <ScrollShadow hideScrollBar className="h-[50vh] sm:h-[40vh]">
            {!privacyAgreement?.content && loading && (
              <div className="flex justify-center">
                <SvgSpinnersBarsScale className="text-2xl text-primary" />
              </div>
            )}

            <div className="my-2 min-h-[30vh] text-foreground">
              <FeaturesRenderText data={privacyAgreement} />
            </div>
          </ScrollShadow>
        </div>
      </BaseModal>
    </>
  );
};

interface IProps {
  children: ReactNode;
  privacyAgreement: any;
  title: string;
  onClick?: any;
  loading?: boolean;
}
