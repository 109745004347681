import FeaturesNotLoggedInLayout from '@/components/features/featuresNotLoggedInLayout';
import RegisterContentForm from '@/pages/public/register/components/RegisterContentForm';
import { useTranslation } from 'react-i18next';
import useStateHooks from './useStateHooks';


/**
 *登录
 */
const LoginView = () => {
  const props = useStateHooks();
  const { t } = useTranslation();
  return (
    <FeaturesNotLoggedInLayout title={t('创建个人账户')}>
      <RegisterContentForm {...props} />
    </FeaturesNotLoggedInLayout>
  );
};
export default LoginView;
